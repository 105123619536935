import AppDashboardApi from "Api/Factory/AppDashboard";
import { EntityId } from "Entities";
import { DashboardEntity } from "Entities/Dashboard";
import EventService from "Services/EventEmitter";

export default class DashboardsStore {
	private static instance: DashboardsStore;
	private readonly event = new EventService();
	private readonly appDashboardApi = AppDashboardApi.getInstance();

	private dashboards: DashboardEntity[] = [];

	public static getInstance(): DashboardsStore {
		return (this.instance = this.instance ?? new DashboardsStore());
	}

	private async fetchDashboards() {
		const dashboards = await this.appDashboardApi.getDashboards();
		this.setDashboards(dashboards);
	}

	public async initDashboards() {
		await this.fetchDashboards();
	}

	private updateDashboards(updatedDashboard: DashboardEntity) {
		const dashboardIndex = this.dashboards.findIndex(({ id }) => updatedDashboard.id === id);
		if (dashboardIndex === -1) {
			throw new Error("Dashboard not exist");
		}

		this.dashboards[dashboardIndex] = updatedDashboard;

		this.setDashboards([...this.dashboards]);
	}

	public async createDashboard() {
		await this.appDashboardApi.createDashboard();
		await this.fetchDashboards();
	}

	public async duplicateDashboard(dashboardId: EntityId) {
		await this.appDashboardApi.duplicateDashboard(dashboardId);
		await this.fetchDashboards();
	}

	public async deleteDashboard(dashboardId: EntityId) {
		await this.appDashboardApi.deleteDashboard(dashboardId);
		await this.fetchDashboards();
	}

	public async renameDashboard(dashboardId: EntityId, dashboardName: string) {
		const dashboardExists = this.dashboards.some(({ id }) => dashboardId === id);
		if (!dashboardExists) {
			throw new Error("Dashboard not exist");
		}

		const updatedDashboard = await this.appDashboardApi.renameDashboard(dashboardId, dashboardName);
		this.updateDashboards(updatedDashboard);
	}

	public getDashboards() {
		return this.dashboards;
	}

	public setDashboards(dashboards: DashboardEntity[]) {
		this.dashboards = dashboards;
		this.event.emit("change", dashboards);
	}

	public onChange(callback: (dashboards: DashboardEntity[]) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off("change", callback);
		};
	}

	public disconnect() {
		this.setDashboards([]);
	}
}
