import { SxProps, Theme } from "@mui/material";

// TODO style root
export const root: SxProps<Theme> = (theme) => ({
	minWidth: "500px",
});

export const header: SxProps<Theme> = {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};
