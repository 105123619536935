import React from "react";
import { useLocation, useParams } from "react-router-dom";

type Props = {
	children: JSX.Element;
};

export default function WithLocation(props: Props): JSX.Element {
	const location = useLocation();
	const params = useParams();

	// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
	const { children, ...rest } = props;

	const forwardProps = { ...rest, location, params };
	return <>{React.cloneElement(props.children, forwardProps)}</>;
}

export class UndefinedLocationError extends Error {
	public constructor(componentName: string) {
		const message = `location and params are undefined, ${componentName} component needs to be wrapped in 'WithLocation' component`;
		console.error(message);
		super(message);
	}
}
