import React from "react";
import { useGoogleLogout } from "@leecheuk/react-google-login";
import { Logout } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import AppAuthApi from "Api/Factory/AppAuth";
import Config from "Configs/Config";
import JwtStore from "Stores/JwtStore";

import I18n from "../I18n";

export default function GoogleLogoutButton() {
	const jwtStore = JwtStore.getInstance();
	const appAuthApi = AppAuthApi.getInstance();

	const { signOut } = useGoogleLogout({
		clientId: Config.getInstance().get().googleOauth.web.client_id,
		onLogoutSuccess: async () => {
			try {
				const { jwtPair } = await appAuthApi.signOut();
				jwtStore.setJwtPair(jwtPair);
			} catch (err) {
				console.error(err);
			}
		},
	});

	return <LogoutButton signOut={signOut} />;
}

type IProps = { signOut: () => void };
type IState = Record<string, never>;

class LogoutButton extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
	}

	public override render(): React.ReactNode {
		return (
			<Button variant="outlined" onClick={this.props.signOut}>
				<Logout />
				<Typography variant="body2" ml={2}>
					<I18n map={"components.dashboard_top_bar.logout_button"} />
				</Typography>
			</Button>
		);
	}
}
