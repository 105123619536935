import { SxProps, Theme } from "@mui/material";
import { headerHeight } from "Themes/constants";

export const root: SxProps<Theme> = (theme) => ({
	display: "flex",
	alignItems: "center",
	height: headerHeight,
	margin: theme.spacing(0, 3),
	justifyContent: "space-between",
});
