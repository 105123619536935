import React from "react";
import { ArrowForward, Cancel } from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, Typography } from "@mui/material";
import I18n from "Components/Materials/I18n";
import WidgetDetails from "Components/Materials/WidgetDetails";
import { WidgetEntity } from "Entities/Widget";
import SelectedDashboardStore from "Stores/SelectedDashboardStore";

import * as styles from "./styles";

type IProps = {
	widget: WidgetEntity;
};
type IState = {
	isEditing: boolean;
	isViewMore: boolean;
};

export default class Widget extends React.Component<IProps, IState> {
	private removeOnChangeIsEditing = () => {};
	private selectedDashboardStore = SelectedDashboardStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isEditing: false,
			isViewMore: false,
		};
		this.viewMore = this.viewMore.bind(this);
		this.closeMore = this.closeMore.bind(this);
	}

	private viewMore() {
		this.setState({
			isViewMore: true,
		});
	}

	private closeMore() {
		this.setState({
			isViewMore: false,
		});
	}

	public override render() {
		return (
			<>
				<WidgetDetails open={this.state.isViewMore} close={this.closeMore} widget={this.props.widget} />
				<Card sx={styles.root}>
					<CardContent>
						<Box sx={styles.header}>
							{this.state.isEditing && (
								<IconButton size="small" sx={styles.deleteButton}>
									<Cancel color="error" fontSize="small" />
								</IconButton>
							)}

							<Typography>Widget N°{this.props.widget.id}</Typography>

							<Button onClick={this.viewMore}>
								<Typography variant="body2" mr={2}>
									<I18n map="components.widget.view_more_button" />
								</Typography>
								<ArrowForward fontSize="small" />
							</Button>
						</Box>
						<Typography>Widget content</Typography>
					</CardContent>
				</Card>
			</>
		);
	}

	public override componentDidMount(): void {
		this.removeOnChangeIsEditing = this.selectedDashboardStore.onChangeIsEditing((isEditing) => {
			this.setState({
				isEditing,
			});
		});
	}

	override componentWillUnmount(): void {
		this.removeOnChangeIsEditing();
	}
}
