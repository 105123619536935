import React from "react";
import { NavigateFunction } from "react-router-dom";
import { ContentCopyOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Button, Menu, Typography } from "@mui/material";
import Toast, { defaultFeedbackError, ToastFeedback } from "Components/Elements/Toast";
import { UndefinedNavigateError } from "Components/Elements/WithNavigation";
import I18n from "Components/Materials/I18n";
import { DashboardEntity } from "Entities/Dashboard";
import DashboardsStore from "Stores/DashboardsStore";
import SelectedDashboardStore from "Stores/SelectedDashboardStore";

import * as styles from "./styles";

type IProps = {
	navigate?: NavigateFunction;
	anchorEl: HTMLButtonElement | null;
	dashboard: DashboardEntity;
	showRename: () => void;
	closeMenu: () => void;
};
type IState = { feedback?: ToastFeedback };

export default class MenuMore extends React.Component<IProps, IState> {
	private readonly dashboardsStore = DashboardsStore.getInstance();
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			feedback: undefined,
		};

		this.showRename = this.showRename.bind(this);
		this.duplicateDashboard = this.duplicateDashboard.bind(this);
		this.deleteDashboard = this.deleteDashboard.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
	}

	private showRename(event: React.UIEvent) {
		event.stopPropagation();
		this.props.showRename();
	}

	private async duplicateDashboard(event: React.UIEvent) {
		event.stopPropagation();

		try {
			await this.dashboardsStore.duplicateDashboard(this.props.dashboard.id);
			this.props.closeMenu();
		} catch {
			this.setState({ feedback: defaultFeedbackError });
		}
	}

	private async deleteDashboard(event: React.UIEvent) {
		event.stopPropagation();

		try {
			await this.dashboardsStore.deleteDashboard(this.props.dashboard.id);
			this.props.closeMenu();

			const isNotSelectedDashboard = this.selectedDashboardStore.getDashboard()?.id !== this.props.dashboard.id;
			if (isNotSelectedDashboard) {
				return;
			}

			const fallbackDashboardId = this.dashboardsStore.getDashboards()[0]?.id;

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.props.navigate!(`${fallbackDashboardId || ""}`);
		} catch {
			this.setState({ feedback: defaultFeedbackError });
		}
	}

	private closeMenu(event: React.UIEvent) {
		event.stopPropagation();
		this.props.closeMenu();
	}

	public override render(): React.ReactNode {
		if (!this.props.navigate) {
			throw new UndefinedNavigateError("MenuMore");
		}

		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Menu
					open
					onClose={this.props.closeMenu}
					anchorEl={this.props.anchorEl}
					sx={styles.root}
					onClick={this.closeMenu}>
					<Box sx={styles.content}>
						<Button sx={styles.button} color="info" onClick={this.showRename}>
							<EditOutlined sx={styles.icon} />
							<Typography variant="body2">
								<I18n map="components.side_menu.menu_more.rename_button" />
							</Typography>
						</Button>

						<Button sx={styles.button} color="info" onClick={this.duplicateDashboard}>
							<ContentCopyOutlined sx={styles.icon} />
							<Typography variant="body2">
								<I18n map="components.side_menu.menu_more.duplicate_button" />
							</Typography>
						</Button>

						<Button sx={styles.button} color="info" onClick={this.deleteDashboard}>
							<DeleteOutline sx={styles.icon} color="error" />
							<Typography variant="body2" color="error">
								<I18n map="components.side_menu.menu_more.delete_button" />
							</Typography>
						</Button>
					</Box>
				</Menu>
			</>
		);
	}
}
