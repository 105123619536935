import AppDashboardApi from "Api/Factory/AppDashboard";
import { EntityId } from "Entities";
import { DashboardEntity } from "Entities/Dashboard";
import EventService from "Services/EventEmitter";

export default class SelectedDashboardStore {
	private static instance: SelectedDashboardStore;
	private readonly event = new EventService();
	private readonly appDashboardApi = AppDashboardApi.getInstance();

	private dashboardId: EntityId | null = null;
	private dashboard: DashboardEntity | null = null;
	private isEditing = false;

	private constructor() {}

	public static getInstance(): SelectedDashboardStore {
		return (this.instance = this.instance ?? new SelectedDashboardStore());
	}

	public async fetchDashboard() {
		const dashboard = this.dashboardId ? await this.appDashboardApi.getDashboard(this.dashboardId) : null;
		this.setDashboard(dashboard);
	}

	public getDashboard() {
		return this.dashboard;
	}

	public async setDashboardId(dashboardId: EntityId | null) {
		this.dashboardId = dashboardId;
		await this.fetchDashboard();
	}

	private async setDashboard(appDashboard: DashboardEntity | null) {
		this.dashboardId = appDashboard?.id || null;
		this.dashboard = appDashboard;
		this.event.emit("changeSelected", this.dashboard);
	}

	public getIsEditing() {
		return this.isEditing;
	}

	public setIsEditing(isEditing: boolean) {
		this.isEditing = isEditing;
		this.event.emit("changeIsEditing", this.isEditing);
	}

	public onChangeSelected(callback: (selectedDashboard: DashboardEntity) => void) {
		this.event.on("changeSelected", callback);
		return () => {
			this.event.off("changeSelected", callback);
		};
	}

	public onChangeIsEditing(callback: (isEditing: boolean) => void) {
		this.event.on("changeIsEditing", callback);
		return () => {
			this.event.off("changeIsEditing", callback);
		};
	}

	public disconnect() {
		this.setDashboard(null);
	}
}
