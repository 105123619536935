import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { EntityId } from "Entities";
import { DashboardEntity } from "Entities/Dashboard";

export default class AppDashboardApi extends BaseApiService {
	private static instance: AppDashboardApi;
	private readonly baseUrl = this.rootUrl.concat(Config.getInstance().get().app).concat("/app-dashboards");

	private constructor() {
		super();
	}

	public static getInstance(): AppDashboardApi {
		return (this.instance = this.instance ?? new AppDashboardApi());
	}

	public async getDashboards(): Promise<DashboardEntity[]> {
		const url = new URL(this.baseUrl);
		try {
			return await this.getRequest<DashboardEntity[]>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getDashboard(dashboardId: EntityId): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			return await this.getRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async createDashboard(): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl);
		try {
			return await this.postRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async renameDashboard(dashboardId: EntityId, dashboardName: string): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			return await this.putRequest<DashboardEntity>(url, { id: dashboardId, name: dashboardName });
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async duplicateDashboard(dashboardId: EntityId): Promise<DashboardEntity> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			return await this.postRequest<DashboardEntity>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async deleteDashboard(dashboardId: EntityId): Promise<void> {
		const url = new URL(this.baseUrl.concat(`/${dashboardId}`));
		try {
			await this.deleteRequest<void>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
