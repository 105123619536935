/* eslint-disable @typescript-eslint/no-non-null-assertion */
import constants from "./constants.module.scss";

export const screenXL = constants["screen-xl"]!;
export const screenLG = constants["screen-lg"]!;
export const screenMD = constants["screen-md"]!;
export const screenSM = constants["screen-sm"]!;
export const screenXS = constants["screen-xs"]!;

export const customEasing = constants["custom-easing"]!;

export const colorGreenCEA = constants["color-green-CEA"]!;
export const colorGreenCEADark = constants["color-green-CEA-dark"]!;
export const colorGreenCEALight = constants["color-green-CEA-light"]!;

export const colorGenericWhite = constants["color-generic-white"]!;
export const colorGenericBlack = constants["color-generic-black"]!;

export const colorGreyDark = constants["color-gray-dark"]!;
export const colorGreyMedium = constants["color-gray-medium"]!;
export const colorGrayLight = constants["color-gray-light"]!;

export const colorSuccess = constants["color-success"]!;
export const colorDestructive = constants["color-destructive"]!;
export const colorDisabled = constants["color-disabled"]!;

export const boxShadow = constants["box-shadow"]!;

export const borderRadius = constants["border-radius"]!;

export const spacingXS = constants["spacing-xs"]!;
export const spacingSM = constants["spacing-sm"]!;
export const spacingMD = constants["spacing-md"]!;
export const spacingLG = constants["spacing-lg"]!;
export const spacingXL = constants["spacing-xl"]!;
export const spacingXXL = constants["spacing-xxl"]!;

export const fontSizeMedium = constants["font-size-medium"]!;

export const headerHeight = constants["header-height"]!;
export const footerHeight = constants["footer-height"]!;

export const sideMenuWidth = constants["side-menu-width"]!;

export const errorTimer = +constants["error-timer"]!;
export const successTimer = +constants["success-timer"]!;
export const infoTimer = +constants["info-timer"]!;
export const warningTimer = +constants["warning-timer"]!;
