import { EntityId } from "./../index";
import { RoleEntity } from "./role";

export type RuleEntity = {
	id: EntityId;
	name: RuleNames;
	action: RuleActions;
	appRoles?: RoleEntity[];
};

export enum RuleActions {
	SIGNIN_SELF = "signin-self",
	SIGNOUT_SELF = "signout-self",
	CREATE_SELF = "create-self",
	READ = "read",
	READ_SELF = "read-self",
	CREATE = "create",
	UPDATE = "update",
}

export enum RuleNames {
	USERS = "users",
	DASHBOARDS = "dashboards",
}
