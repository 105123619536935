import React from "react";
import ReactDOM from "react-dom/client";
import AutoLoadServices from "Services/AutoLoadServices";

import Main from "./Components/Main";
import reportWebVitals from "./reportWebVitals";

import "./index.scss";

AutoLoadServices.load();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Main />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
