import { SxProps, Theme } from "@mui/material";
import { colorGrayLight, headerHeight, sideMenuWidth } from "Themes/constants";

export const root: SxProps<Theme> = {
	display: "flex",
	alignItems: "center",
	height: headerHeight,
};

export const iconWrapper: SxProps<Theme> = {
	width: sideMenuWidth,
};

export const mainContent: SxProps<Theme> = (theme) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	flex: 1,
	padding: theme.spacing(0, 3),
	backgroundColor: colorGrayLight,
	height: "100%",
});

export const buttons: SxProps<Theme> = {
	display: "flex",
	alignItems: "center",
};

export const firstButton: SxProps<Theme> = (theme) => ({
	marginRight: theme.spacing(2),
});

export const editWidgetsButton: (isVisible: boolean) => SxProps<Theme> = (isVisible) => (theme) => ({
	...firstButton(theme),
	visibility: isVisible ? "inherit" : "hidden",
});
