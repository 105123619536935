import AppAuthApi from "Api/Factory/AppAuth";
import { JwtPair } from "Entities/AuthFactory/accessToken";
import jwtDecode, { JwtPayload } from "jwt-decode";
import JwtStore from "Stores/JwtStore";
import UserStore from "Stores/UserStore";

export default class StoreWorflow {
	private static instance: StoreWorflow;
	private readonly appAuthApi = AppAuthApi.getInstance();
	private readonly jwtStore = JwtStore.getInstance();
	private readonly userStore = UserStore.getInstance();

	private constructor() {
		this.initEvents();
		const hasJwtPair = !!this.jwtStore.getAccessToken() && !!this.jwtStore.getRefreshToken();
		if (!hasJwtPair) {
			this.appAuthApi.presign().then((res) => {
				this.jwtStore.setJwtPair(res.jwtPair);
			});
		}
	}

	public static getInstance(): StoreWorflow {
		return (this.instance = this.instance ?? new StoreWorflow());
	}

	private initEvents() {
		this.onJwtPairChange();
	}

	private onJwtPairChange() {
		this.jwtStore.onChange((jwtPair?: JwtPair) => {
			if (!jwtPair) {
				this.userStore.setUser(null);
				return;
			}

			const decodedToken = jwtDecode<JwtPayload>(jwtPair.accessToken);
			if (!decodedToken.sub) {
				this.userStore.setUser(null);
				return;
			}

			this.userStore.setUser({ id: +decodedToken.sub });
		});
	}

	public disconnect() {
		this.userStore.disconnect();
	}
}
