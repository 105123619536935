import { NavigateFunction, Params } from "react-router-dom";
import { Box } from "@mui/material";
import { UndefinedLocationError } from "Components/Elements/WithLocation";
import { UndefinedNavigateError } from "Components/Elements/WithNavigation";
import Dashboard from "Components/Materials/Dashboard";
import DashboardsTopBar from "Components/Materials/DashboardTopBar";
import I18n from "Components/Materials/I18n";
import SideMenu from "Components/Materials/SideMenu";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";
import { UserEntity } from "Entities/AuthFactory/user";
import { DashboardEntity } from "Entities/Dashboard";
import DashboardsStore from "Stores/DashboardsStore";
import SelectedDashboardStore from "Stores/SelectedDashboardStore";
import UserStore from "Stores/UserStore";

type IProps = {
	params?: Params;
	navigate?: NavigateFunction;
};
type IState = {
	user: UserEntity | null;
};

export default class DashBoards extends BasePage<IProps, IState> {
	private removeOnChange = () => {};
	private readonly userStore = UserStore.getInstance();
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	private readonly dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			user: null,
		};
	}

	public override render(): React.ReactNode {
		if (!this.props.params) {
			throw new UndefinedLocationError("DashBoards page");
		}
		if (!this.props.navigate) {
			throw new UndefinedNavigateError("DashBoards page");
		}

		return (
			<I18n
				map={"pages.dashboards.title"}
				content={([title]) => (
					<DefaultTemplate title={title} padding={false}>
						<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<DashboardsTopBar />
							<Box sx={{ display: "flex", flex: 1, height: "100%" }}>
								<SideMenu />
								<Dashboard />
							</Box>
						</Box>
					</DefaultTemplate>
				)}
			/>
		);
	}

	private async initSelectedDashboard() {
		const dashboardId = Number(this.props.params?.["*"]) || null;
		let selectedDashboard: DashboardEntity | null;
		try {
			await this.selectedDashboardStore.setDashboardId(dashboardId);
			selectedDashboard = this.selectedDashboardStore.getDashboard();
		} catch {
			selectedDashboard = null;
		}

		if (selectedDashboard) {
			return;
		}

		const fallbackDashboardId = this.dashboardsStore.getDashboards()[0]?.id || null;
		await this.selectedDashboardStore.setDashboardId(fallbackDashboardId);

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.props.navigate!(`${fallbackDashboardId || ""}`);
	}

	public override async componentDidMount(): Promise<void> {
		this.removeOnChange = this.userStore.onChange(async (user) => {
			this.setState({
				user,
			});
		});

		await this.dashboardsStore.initDashboards();
		await this.initSelectedDashboard();
	}

	public override async componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.params?.["*"] === this.props.params?.["*"]) {
			return;
		}

		this.initSelectedDashboard();
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}
}
