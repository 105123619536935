import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { RuleActions, RuleNames } from "Entities/AuthFactory/rule";
import RouterLocation from "Stores/RouterLocation";
import Version, { IConfig } from "Stores/Version";

import WithLocation from "./Elements/WithLocation";
import WithNavigation from "./Elements/WithNavigation";
import Module from "./Materials/Module";
import Rules, { RulesMode } from "./Materials/Rules";
import DashBoards from "./Pages/Dashboards";
import Landing from "./Pages/Home";

type IProps = Record<string, never>;

type IState = {
	version: IConfig | null;
};

export default class MainRoutes extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			version: Version.getInstance().version,
		};
	}

	public override render(): JSX.Element {
		return (
			<BrowserRouter basename="/">
				<Routes>
					<Route element={<BindRouter version={this.state.version} />}>
						{this.routes()}
						<Route element={<Navigate to="/" replace />} path="*" />
					</Route>
				</Routes>
			</BrowserRouter>
		);
	}

	private routes(): React.ReactElement | null {
		const pageConfig = Module.config.pages;
		return (
			<>
				<Route
					element={
						<Module from={pageConfig.Dashboards} isPage>
							<Rules
								mode={RulesMode.NECESSARY}
								rules={[{ name: RuleNames.DASHBOARDS, action: RuleActions.READ }]}
								isPage>
								<WithNavigation>
									<WithLocation>
										<DashBoards />
									</WithLocation>
								</WithNavigation>
							</Rules>
						</Module>
					}
					path={pageConfig.Dashboards.props.path.concat("/*")}>
					<Route element={<Navigate to="/dashboards" replace />} path="*" />
				</Route>

				<Route
					element={
						<Module from={pageConfig.Home} isPage>
							<Landing />
						</Module>
					}
					path={pageConfig.Home.props.path.concat("/*")}>
					<Route element={<Navigate to="/" replace />} path="*" />
				</Route>
			</>
		);
	}
}

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
function BindRouter({ version }: { version: IConfig | null }) {
	const location = useLocation();
	RouterLocation.getInstance().preSet(location);
	useEffect(() => {
		RouterLocation.getInstance().change(location);
		window.scrollTo(0, 0);
		document.body.setAttribute("route", location.pathname);
	}, [location.pathname]);
	return <Outlet />;
}
