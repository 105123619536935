import React from "react";
import { Box } from "@mui/material";
import Widget from "Components/Materials/Widget";

import * as styles from "./styles";

export default class Dashboard extends React.Component {
	public override render(): React.ReactNode {
		return (
			<Box sx={styles.root}>
				<Widget widget={{ id: 1 }} />
			</Box>
		);
	}
}
