import { SxProps, Theme } from "@mui/material";

export const root: SxProps<Theme> = {
	position: "relative",
	overflow: "visible",
	minWidth: "500px",
};

export const header: SxProps<Theme> = {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

export const deleteButton: SxProps<Theme> = {
	position: "absolute",
	top: "-15px",
	left: "-15px",
};
