import { createTheme } from "@mui/material";

import {
	colorGenericBlack,
	colorGenericWhite,
	colorGrayLight,
	colorGreenCEA,
	colorGreenCEADark,
	colorGreenCEALight,
	colorGreyDark,
	colorSuccess,
	fontSizeMedium,
	spacingLG,
	spacingMD,
	spacingSM,
	spacingXL,
	spacingXS,
	spacingXXL,
} from "./constants";

export const theme = createTheme({
	palette: {
		primary: {
			main: colorGreenCEA,
			light: colorGreenCEALight,
			dark: colorGreenCEADark,
			contrastText: colorGenericWhite,
		},
		success: {
			main: colorSuccess,
		},
		info: {
			main: colorGreyDark,
			dark: colorGenericBlack,
		},
	},
	typography: {
		body1: {
			fontSize: fontSizeMedium,
			color: colorGreyDark,
		},
	},
	spacing: [0, spacingXS, spacingSM, spacingMD, spacingLG, spacingXL, spacingXXL],

	components: {
		MuiButton: {
			styleOverrides: {
				sizeSmall: {
					height: "30px",
				},
				sizeMedium: {
					height: "36px",
				},
				sizeLarge: {
					height: "40px",
				},
				outlinedInfo: {
					":hover": {
						backgroundColor: colorGrayLight,
					},
				},
				outlinedPrimary: {
					":hover": {
						backgroundColor: colorGreenCEALight,
					},
				},
				textPrimary: {
					":hover": {
						backgroundColor: colorGreenCEALight,
					},
				},
				contained: {
					"*": {
						color: colorGenericWhite,
					},
					":hover": {
						backgroundColor: colorGreenCEADark,
					},
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				input: {
					height: spacingMD,
				},
			},
		},

		MuiSvgIcon: {
			styleOverrides: {
				fontSizeSmall: {
					fontSize: "16px",
				},
				fontSizeMedium: {
					fontSize: "20px",
				},
			},
		},
	},
});
