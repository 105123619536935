import React from "react";
import { Box, Typography } from "@mui/material";
import I18n from "Components/Materials/I18n";

import * as styles from "./styles";

type IProps = {
	title: string;
	children?: React.ReactNode;
	className?: string;
	padding?: boolean;
};

export default class DefaultTemplate extends React.Component<IProps> {
	static defaultProps: IProps = {
		title: "",
		padding: true,
	};

	public override render(): React.ReactNode {
		return (
			<I18n>
				<Box sx={styles.content}>{this.props.children}</Box>

				<footer>
					<Box sx={styles.footer}>
						<Typography variant="body1">Footer</Typography>
					</Box>
				</footer>
			</I18n>
		);
	}

	public override componentDidUpdate() {
		this.updateWindowTitle();
	}

	public override componentDidMount() {
		this.updateWindowTitle();
	}

	private updateWindowTitle() {
		window.document.title = this.props.title;
	}
}
