import React from "react";
import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { WidgetEntity } from "Entities/Widget";

import * as styles from "./styles";

type IProps = {
	open: boolean;
	close: () => void;
	widget: WidgetEntity;
};
type IState = Record<string, never>;

export default class WidgetDetails extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Dialog sx={styles.root} open={this.props.open} onClose={this.props.close}>
				<DialogContent>
					<Box sx={styles.header}>
						<Typography>Widget N°{this.props.widget.id}</Typography>

						<IconButton color="info" onClick={() => {}}>
							<Close fontSize="small" />
						</IconButton>
					</Box>
					<Typography>Widget details</Typography>
				</DialogContent>
			</Dialog>
		);
	}
}
