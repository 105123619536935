import React from "react";
import { Link } from "react-router-dom";
import { Replay } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import ceaLogoIcon from "assets/images/icons/cea-logo-icon.svg";
import ceaLogoText from "assets/images/icons/cea-logo-text.svg";
import { DashboardEntity } from "Entities/Dashboard";
import SelectedDashboardStore from "Stores/SelectedDashboardStore";
import { spacingMD, spacingXS } from "Themes/constants";

import GoogleLogoutButton from "../GoogleLogoutButton";
import I18n from "../I18n";

import * as styles from "./styles";

type IProps = Record<string, never>;
type IState = {
	selectedDashboard: DashboardEntity | null;
	isEditing: boolean;
};

export default class DashboardsTopBar extends React.Component<IProps, IState> {
	private removeOnChangeSelectedDashboard = () => {};
	private removeOnChangeIsEditingDashboard = () => {};
	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();

	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedDashboard: null,
			isEditing: false,
		};
		this.handleEdit = this.handleEdit.bind(this);
		this.handleResetEdition = this.handleResetEdition.bind(this);
		this.handleSaveEdition = this.handleSaveEdition.bind(this);
	}

	private handleEdit() {
		this.selectedDashboardStore.setIsEditing(true);
	}

	private handleResetEdition() {
		this.selectedDashboardStore.setIsEditing(false);
	}

	private handleSaveEdition() {
		this.selectedDashboardStore.setIsEditing(false);
	}

	public override render(): React.ReactNode {
		return (
			<Box sx={(theme) => ({ backgroundColor: theme.palette.background.default })}>
				<header>
					<Box sx={styles.root}>
						<Box sx={styles.iconWrapper}>
							<Link to="/">
								<img src={ceaLogoIcon} style={{ marginLeft: spacingMD, marginRight: spacingXS }} />
								<img src={ceaLogoText} />
							</Link>
						</Box>

						<Box sx={styles.mainContent}>
							<Typography variant="h4" visibility={this.state.selectedDashboard ? "initial" : "hidden"}>
								{this.state.selectedDashboard?.name}
							</Typography>

							{!this.state.isEditing && (
								<Box sx={styles.buttons}>
									<Button
										sx={styles.editWidgetsButton(!!this.state.selectedDashboard)}
										variant="contained"
										onClick={this.handleEdit}>
										<Typography variant="body2">
											<I18n map="components.dashboard_top_bar.edit_widgets_button" />
										</Typography>
									</Button>
									<GoogleLogoutButton />
								</Box>
							)}

							{this.state.isEditing && (
								<Box visibility={this.state.isEditing ? "visible" : "hidden"} sx={styles.buttons}>
									<Button sx={styles.firstButton} variant="outlined" color="info" onClick={this.handleResetEdition}>
										<Replay />
										<Typography variant="body2" ml={2}>
											<I18n map="components.dashboard_top_bar.reset_edition_button" />
										</Typography>
									</Button>

									<Button variant="contained" onClick={this.handleSaveEdition}>
										<Typography variant="body2">
											<I18n map="components.dashboard_top_bar.save_edition_button" />
										</Typography>
									</Button>
								</Box>
							)}
						</Box>
					</Box>
				</header>
			</Box>
		);
	}

	public override async componentDidMount() {
		this.removeOnChangeSelectedDashboard = this.selectedDashboardStore.onChangeSelected((selectedDashboard) => {
			this.setState({
				selectedDashboard,
			});
		});

		this.removeOnChangeIsEditingDashboard = this.selectedDashboardStore.onChangeIsEditing((isEditing) => {
			this.setState({
				isEditing,
			});
		});
	}

	public override componentWillUnmount() {
		this.removeOnChangeSelectedDashboard();
		this.removeOnChangeIsEditingDashboard();
	}
}
