import BaseApiService from "Api/BaseApiService";
import Config from "Configs/Config";
import { JwtPair } from "Entities/AuthFactory/accessToken";
import { UserEntity } from "Entities/AuthFactory/user";

type IPresignResponse = {
	jwtPair: JwtPair;
};

type IPostSignin = {
	oAuthToken: string;
};

type ISignInResponse = {
	user: UserEntity;
	jwtPair: JwtPair;
};

type ISignOutResponse = {
	jwtPair: JwtPair;
};

export default class AppAuthApi extends BaseApiService {
	private static instance: AppAuthApi;
	private readonly baseUrl = this.authFactoryUrl.concat("/app-auth/").concat(Config.getInstance().get().app);

	private constructor() {
		super();
	}

	public static getInstance(): AppAuthApi {
		return (this.instance = this.instance ?? new AppAuthApi());
	}

	public async presign(): Promise<IPresignResponse> {
		const url = new URL(this.baseUrl.concat("/presign"));
		try {
			return await this.postRequest<IPresignResponse>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signInWithOAuth(params: IPostSignin): Promise<ISignInResponse> {
		const url = new URL(this.baseUrl.concat("/sign-in-with-o-auth"));
		try {
			return await this.postRequest<ISignInResponse>(url, params);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async signOut(): Promise<ISignOutResponse> {
		const url = new URL(this.baseUrl.concat("/sign-out"));
		try {
			return await this.putRequest<ISignOutResponse>(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
