import React from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLoginResponse, useGoogleLogin } from "@leecheuk/react-google-login";
import { Button, Typography } from "@mui/material";
import AppAuthApi from "Api/Factory/AppAuth";
import GoogleIcon from "assets/images/icons/google.svg";
import Config from "Configs/Config";
import { DecodedAccessToken } from "Entities/AuthFactory/accessToken";
import jwtDecode from "jwt-decode";
import JwtStore from "Stores/JwtStore";

import I18n from "../I18n";

export default function GoogleLoginButton() {
	const navigate = useNavigate();
	const config = Config.getInstance();
	const appAuthApi = AppAuthApi.getInstance();
	const jwtStore = JwtStore.getInstance();
	const accessToken = jwtStore.getAccessToken();

	const { signIn } = useGoogleLogin({
		clientId: config.get().googleOauth.web.client_id,
		isSignedIn: !!accessToken && !!jwtDecode<DecodedAccessToken>(accessToken).sub,
		cookiePolicy: "single_host_origin",
		onSuccess: async (response) => {
			const res = response as GoogleLoginResponse;
			const oAuthToken = res.tokenObj.id_token;
			const { jwtPair } = await appAuthApi.signInWithOAuth({ oAuthToken });
			jwtStore.setJwtPair(jwtPair);
			navigate("/dashboards");
		},
	});

	return <LoginButton signIn={signIn} />;
}

type IProps = {
	signIn: () => void;
};
type IState = Record<string, never>;

class LoginButton extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
	}

	public override render(): React.ReactNode {
		return (
			<Button variant="outlined" onClick={this.props.signIn}>
				<img src={GoogleIcon} style={{ width: "20px" }} />
				<Typography variant="body2" ml={2}>
					<I18n map={"components.home_top_bar.login_google_button"} />
				</Typography>
			</Button>
		);
	}
}
