import { Box, Typography } from "@mui/material";
import WithLocation from "Components/Elements/WithLocation";
import HomeTopBar from "Components/Materials/HomeTopBar";
import I18n from "Components/Materials/I18n";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplates/DefaultTemplate";

export default class Home extends BasePage {
	public override render(): React.ReactNode {
		return (
			<I18n
				map={"pages.home.title"}
				content={([title]) => (
					<DefaultTemplate title={title} padding={false}>
						<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
							<WithLocation>
								<HomeTopBar />
							</WithLocation>
							<Box sx={{ display: "flex" }}>
								<Typography variant="body1">
									<I18n map="pages.home.text" />
								</Typography>
							</Box>
						</Box>
					</DefaultTemplate>
				)}
			/>
		);
	}
}
