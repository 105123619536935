import { SxProps, Theme } from "@mui/material";
import { colorGrayLight } from "Themes/constants";

export const root: (isShowingMenu: boolean) => SxProps<Theme> = (isShowingMenu: boolean) => (theme) => ({
	textTransform: "none",
	width: "100%",
	justifyContent: "space-between",
	backgroundColor: isShowingMenu ? theme.palette.primary.light : "initial",
	marginBottom: theme.spacing(1),
	height: theme.spacing(6),
});

export const icon: (isShowingMenu: boolean) => SxProps<Theme> = (isShowingMenu: boolean) => (theme) => ({
	display: "flex",
	alignItems: "center",
	color: theme.palette.info.main,
	backgroundColor: isShowingMenu ? colorGrayLight : "initial",
	borderRadius: "100%",
	":hover": {
		backgroundColor: colorGrayLight,
	},
});
