import React from "react";
import { NavigateFunction } from "react-router-dom";
import { MoreHoriz } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import WithNavigation, { UndefinedNavigateError } from "Components/Elements/WithNavigation";
import { DashboardEntity } from "Entities/Dashboard";
import SelectedDashboardStore from "Stores/SelectedDashboardStore";

import MenuMore from "../MenuMore";
import RenameDashboard from "../RenameDashboard";

import * as styles from "./styles";

type IProps = {
	navigate?: NavigateFunction;
	dashboard: DashboardEntity;
};
type IState = {
	isShowingMenu: boolean;
	isShowingRename: boolean;
	isSelectedDashboard: boolean;
};

export default class DashboardItem extends React.Component<IProps, IState> {
	private removeOnChange = () => {};

	private readonly selectedDashboardStore = SelectedDashboardStore.getInstance();
	private readonly itemRef = React.createRef<HTMLButtonElement>();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			isShowingMenu: false,
			isShowingRename: false,
			isSelectedDashboard: false,
		};

		this.toggleMenu = this.toggleMenu.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.showRename = this.showRename.bind(this);
		this.closeRename = this.closeRename.bind(this);
		this.selectDashboard = this.selectDashboard.bind(this);
	}

	public toggleMenu() {
		this.setState({ isShowingMenu: !this.state.isShowingMenu });
	}

	public closeMenu() {
		this.setState({ isShowingMenu: false });
	}

	public showRename() {
		this.setState({ isShowingRename: true });
	}

	public closeRename() {
		this.setState({ isShowingRename: false });
		this.closeMenu();
	}

	private selectDashboard() {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.props.navigate!(`${this.props.dashboard.id}`);
	}

	public override render(): React.ReactNode {
		if (!this.props.navigate) {
			throw new UndefinedNavigateError("DashboardItem");
		}

		return (
			<>
				{this.state.isShowingRename && (
					<RenameDashboard dashboard={this.props.dashboard} closeRename={this.closeRename} />
				)}
				{!this.state.isShowingRename && (
					<Button
						ref={this.itemRef}
						sx={styles.root(this.state.isShowingMenu || this.state.isSelectedDashboard)}
						onClick={this.selectDashboard}>
						<Typography variant="body1">{this.props.dashboard.name}</Typography>

						<Box
							sx={styles.icon(this.state.isShowingMenu)}
							onClick={(event) => {
								event.stopPropagation();
								this.toggleMenu();
							}}>
							<MoreHoriz color="info" />
						</Box>

						{this.itemRef.current && this.state.isShowingMenu && (
							<WithNavigation>
								<MenuMore
									anchorEl={this.itemRef.current}
									dashboard={this.props.dashboard}
									showRename={this.showRename}
									closeMenu={this.closeMenu}
								/>
							</WithNavigation>
						)}
					</Button>
				)}
			</>
		);
	}

	public override componentDidMount() {
		this.removeOnChange = this.selectedDashboardStore.onChangeSelected(() => {
			this.setState({
				isSelectedDashboard: this.selectedDashboardStore.getDashboard()?.id === this.props.dashboard.id,
			});
		});

		this.setState({
			isSelectedDashboard: this.selectedDashboardStore.getDashboard()?.id === this.props.dashboard.id,
		});
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}
}
