import React from "react";
import { Box } from "@mui/material";
import WithNavigation from "Components/Elements/WithNavigation";
import { DashboardEntity } from "Entities/Dashboard";
import DashboardsStore from "Stores/DashboardsStore";

import DashboardItem from "../DashboardItem";

import * as styles from "./styles";

type IProps = Record<string, never>;
type IState = {
	dashboards: DashboardEntity[];
};

export default class DashboardList extends React.Component<IProps, IState> {
	private removeOnChange = () => {};
	private readonly dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			dashboards: [],
		};
	}

	public override render(): React.ReactNode {
		return (
			<Box sx={styles.root}>
				{this.state.dashboards.map((dashboard) => (
					<WithNavigation key={dashboard.id}>
						<DashboardItem dashboard={dashboard} />
					</WithNavigation>
				))}
			</Box>
		);
	}

	public override async componentDidMount(): Promise<void> {
		this.removeOnChange = this.dashboardsStore.onChange((dashboards) => {
			this.setState({
				dashboards,
			});
		});

		const dashboards = this.dashboardsStore.getDashboards();
		this.setState({
			dashboards,
		});
	}

	public override componentWillUnmount() {
		this.removeOnChange();
	}
}
