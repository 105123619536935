import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
	children: JSX.Element;
};

export default function WithNavigation(props: Props): JSX.Element {
	const navigate = useNavigate();

	// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
	const { children, ...rest } = props;

	const forwardProps = { ...rest, navigate };
	return <>{React.cloneElement(props.children, forwardProps)}</>;
}

export class UndefinedNavigateError extends Error {
	public constructor(componentName: string) {
		const message = `navigate is undefined, ${componentName} component needs to be wrapped in 'WithNavigation' component`;
		console.error(message);
		super(message);
	}
}
