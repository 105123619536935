import { SxProps, Theme } from "@mui/material";
import { colorGrayLight, colorGreenCEALight, footerHeight } from "Themes/constants";

export const root: SxProps<Theme> = {
	display: "flex",
};

export const content: SxProps<Theme> = {
	display: "flex",
	flex: 1,
	overflowY: "hidden",
	backgroundColor: colorGrayLight,
};

export const footer: SxProps<Theme> = {
	height: footerHeight,
	backgroundColor: colorGreenCEALight,
};
