import React from "react";
import { Add } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import Toast, { defaultFeedbackError, ToastFeedback } from "Components/Elements/Toast";
import I18n from "Components/Materials/I18n";
import DashboardsStore from "Stores/DashboardsStore";

type IProps = Record<string, never>;
type IState = {
	feedback?: ToastFeedback;
};

export default class NewDashboardButton extends React.Component<IProps, IState> {
	private readonly dashboardsStore = DashboardsStore.getInstance();

	public constructor(props: IProps) {
		super(props);
		this.state = {
			feedback: undefined,
		};
		this.handleClick = this.handleClick.bind(this);
	}

	public async handleClick() {
		try {
			await this.dashboardsStore.createDashboard();
		} catch (err: unknown) {
			this.setState({ feedback: defaultFeedbackError });
		}
	}

	public override render(): React.ReactNode {
		return (
			<>
				<Toast feedback={this.state.feedback} onClose={() => this.setState({ feedback: undefined })} />
				<Button onClick={this.handleClick} sx={(theme) => ({ marginBottom: theme.spacing(2), width: "100%" })}>
					<Add fontSize="small" />
					<Typography variant="body2" ml={2}>
						<I18n map="components.side_menu.new_dashboard_button" />
					</Typography>
				</Button>
			</>
		);
	}
}
