import React from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import I18n from "Components/Materials/I18n";
import { errorTimer, infoTimer, successTimer, warningTimer } from "Themes/constants";

export type ToastFeedback = { message: string; severity: AlertColor };

type IProps = {
	feedback?: ToastFeedback;
	onClose: () => void;
};
type IState = Record<string, never>;

const Timers: Record<AlertColor, number> = {
	error: errorTimer,
	info: infoTimer,
	success: successTimer,
	warning: warningTimer,
};

export default class Toast extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
	}

	public override render(): React.ReactNode {
		return (
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={!!this.props.feedback}
				autoHideDuration={Timers[this.props.feedback?.severity || "info"]}
				onClose={this.props.onClose}>
				{this.props.feedback && (
					<Alert variant="filled" severity={this.props.feedback.severity}>
						{this.props.feedback.message}
					</Alert>
				)}
			</Snackbar>
		);
	}
}

export const defaultFeedbackError: ToastFeedback = {
	message: I18n.translate("general_text.generic_error"),
	severity: "error",
};
