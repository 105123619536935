import React from "react";
import { Navigate } from "react-router-dom";
import ModuleConfig from "Configs/Module";

type IProps = {
	children?: React.ReactNode;
	isPage?: boolean;
	from: {
		enabled: boolean;
		props?: {
			[key: string]: unknown;
		};
	};
};

type IState = Record<string, never>;

export default class Module extends React.Component<IProps, IState> {
	public override render(): React.ReactNode | null {
		if (!this.props.from.enabled) {
			return this.props.isPage ? <Navigate to={"/"} /> : null;
		}
		return <>{this.props.children}</>;
	}

	public static get config() {
		return ModuleConfig.getInstance().get().modules;
	}
}
