import React from "react";
import { Box } from "@mui/material";
import NewDashboardButton from "Components/Materials/NewDashboardButton";

import DashboardList from "./DashboardList";
import * as styles from "./styles";

type IProps = Record<string, never>;
type IState = Record<string, never>;

export default class SideMenu extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Box sx={styles.root}>
				<NewDashboardButton />
				<DashboardList />
			</Box>
		);
	}
}
