import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import I18n from "Components/Materials/I18n";

type IProps = Record<string, never>;
type IState = Record<string, never>;

export default class LaunchAppButton extends React.Component<IProps, IState> {
	public override render(): React.ReactNode {
		return (
			<Button variant="contained">
				<Link to="/dashboards">
					<Typography variant="body2">
						<I18n map="components.home_top_bar.launch_app_button" />
					</Typography>
				</Link>
			</Button>
		);
	}
}
